import React from 'react';
import { Helmet } from 'react-helmet';
import createLinkWithLang, { domain } from '../utils/domain';

function SEO({ title, children }) {
  return (
    <Helmet htmlAttributes={{ prefix: 'og: http://ogp.me/ns#' }}>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content="berules" />

      <link rel="dns-prefetch" href="https://fonts.gstatic.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link
        rel="preload"
        href="https://fonts.googleapis.com/css2?family=Muli:wght@400;600;700&family=Nunito+Sans:wght@300;900&display=swap"
        as="fetch"
        crossOrigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Muli:wght@400;600;700&family=Nunito+Sans:wght@300;900&display=swap"
        rel="stylesheet"
      />

      {/* weglot */}
      <link rel="alternate" hrefLang="en" href={domain} />
      {['en', 'fr', 'pt', 'zh', 'es', 'it', 'ru', 'ja', 'ko'].map((lang) => (
        <link rel="alternate" hrefLang={lang} href={createLinkWithLang(lang)} />
      ))}
      {children}
    </Helmet>
  );
}

export default SEO;
